export const environment = {
  production: true,
  staging: false
};

export const serviziRest = {
  "addMenu": "https://www.singlefinapp.it/singlefin-ws/rest/ges/menu/addMenu",
  "deleteMenu": "https://www.singlefinapp.it/singlefin-ws/rest/ges/menu/deleteMenu",
  "deletePiatto": "https://www.singlefinapp.it/singlefin-ws/rest/ges/piattomenu/deletePiattoMenu",
  "modificaPiatto": "https://www.singlefinapp.it/singlefin-ws/rest/ges/piatto/updatePiatto",
  "getMenus": "https://www.singlefinapp.it/singlefin-ws/rest/ges/menu/getMenus",
  "inviaMenu": "https://www.singlefinapp.it/singlefin-ws/rest/ges/menu/inviaMailMenu",
  "getRiepilogoIncassi": "https://www.singlefinapp.it/singlefin-ws/rest/ges/report/getReportIncassi",
  "getReportIncassiOmbrelloniPerUtente": "https://www.singlefinapp.it/singlefin-ws/rest/ges/report/getReportIncassiOmbrelloniPerUtente?idUtente=",
  "getInfoStandard": "https://www.singlefinapp.it/singlefin-ws/rest/info/getInfoStandard",
  "getListaBar": "https://www.singlefinapp.it/singlefin-ws/rest/bar/getListaBar",
  "getListaBarPerCategoria": "https://www.singlefinapp.it/singlefin-ws/rest/bar/getListaBarPerCategoria",
  "getMaxElemento": "https://www.singlefinapp.it/singlefin-ws/rest/bar/getMaxElemento",
  "getParcheggiDisponibili": "https://www.singlefinapp.it/singlefin-ws/rest/parcheggio/getParcheggiDisponibili?data=",
  "getDisponibilitaRistorante": "https://www.singlefinapp.it/singlefin-ws/rest/ristorante/getDisponibilitaRistorante?",
  "bloccaOmbrellone": "https://www.singlefinapp.it/singlefin-ws/rest/ges/ombrellone/bloccaOmbrellone",
  "sbloccaOmbrellone": "https://www.singlefinapp.it/singlefin-ws/rest/ges/ombrellone/sbloccaOmbrellone",
  "loginManuale": "https://www.singlefinapp.it/singlefin-ws/rest/public/users/mLogin",
  "loginFb": "https://www.singlefinapp.it/singlefin-ws/rest/public/users/fbAccess",
  "registrazioneManuale": "https://www.singlefinapp.it/singlefin-ws/rest/public/users/mRegister",
  "createRivenditore": "https://www.singlefinapp.it/singlefin-ws/rest/ges/utente/createRivenditore",
  "getUtente": "https://www.singlefinapp.it/singlefin-ws/rest/utente/getUtente",
  "getListaUtenti": "https://www.singlefinapp.it/singlefin-ws/rest/ges/utente/getUtentiReali",
  "getUtentiByIdRuolo": "https://www.singlefinapp.it/singlefin-ws/rest/ges/utente/getUtentiByIdRuolo?idRuolo=",
  "deleteUtente": "https://www.singlefinapp.it/singlefin-ws/rest/ges/utente/deleteUtente",
  "updateUtente": "https://www.singlefinapp.it/singlefin-ws/rest/ges/utente/updateUtente",
  "getRuoloUtente": "https://www.singlefinapp.it/singlefin-ws/rest/ges/utente/getRuoloUtente",
  "getAllElementoBar": "https://www.singlefinapp.it/singlefin-ws/rest/ges/categoriaBar/getAllCategoriaBar",
  "deleteElementoBar": "https://www.singlefinapp.it/singlefin-ws/rest/ges/elementoBar/deleteElementoBar",
  "updateElementoBar": "https://www.singlefinapp.it/singlefin-ws/rest/ges/elementoBar/updateElementoBar",
  "creaElementoBar": "https://www.singlefinapp.it/singlefin-ws/rest/ges/elementoBar/createElementoBar",
  "getDisponibilitaMassima": "https://www.singlefinapp.it/singlefin-ws/rest/ges/disponibilitaRistorante/getDisponibilitaMassima",
  "updateDisponibilitaRistorante": "https://www.singlefinapp.it/singlefin-ws/rest/ges/disponibilitaRistorante/updateDisponibilitaRistorante",
  "setUtente": "https://www.singlefinapp.it/singlefin-ws/rest/utente/updateUtente",
  "getOmbrelloni": "https://www.singlefinapp.it/singlefin-ws/rest/ges/ombrellone/getOmbrelloniPeriodo?dataInizio=",
  "getOmbrelloniOld": "https://www.singlefinapp.it/singlefin-ws/rest/ges/ombrellone/getOmbrelloni?data=",
  "getPrezziServizi": "https://www.singlefinapp.it/singlefin-ws/rest/ges/servizioOmbrellone/getServizi",
  "updatePrezziServizi": "https://www.singlefinapp.it/singlefin-ws/rest/ges/servizioOmbrellone/updateServizio",
  "getPrezziOmbrellone": "https://www.singlefinapp.it/singlefin-ws/rest/ges/prezzoOmbrellone/getPrezziQuotidiani",
  "updatePrezziOmbrellone": "https://www.singlefinapp.it/singlefin-ws/rest/ges/prezzoOmbrellone/updatePrezzoQuotidiano",

  "getNotizieDisponibili": "https://www.singlefinapp.it/singlefin-ws/rest/ges/notizia/getNotizieDisponibili",
  "insertNotizia": "https://www.singlefinapp.it/singlefin-ws/rest/ges/notizia/insertNotizia",
  "deleteNotizia": "https://www.singlefinapp.it/singlefin-ws/rest/ges/notizia/deleteNotizia",
  "updateNotizia": "https://www.singlefinapp.it/singlefin-ws/rest/ges/notizia/updateNotizia",

  "getOmbrellonePrenotato": "https://www.singlefinapp.it/singlefin-ws/rest/ges/prenotazioneOmbrellone/getPrenotazioneOmbrellone?",
  "getPrenotazioniOmbrellone": "https://www.singlefinapp.it/singlefin-ws/rest/ges/prenotazioneOmbrellone/getPrenotazioniOmbrellone?",
  "getOrdine": "https://www.singlefinapp.it/singlefin-ws/rest/ges/ordine/getOrdine?idOrdine=",
  "bottoneNucleare": "https://www.singlefinapp.it/singlefin-ws/rest//ges/prenotazioneOmbrellone/bottoneNucleare?idFasciaOraria=",
  "insertPrenotazioneOmbr": "https://www.singlefinapp.it/singlefin-ws/rest/ges/prenotazioneOmbrellone/insertPrenotazioneOmbrellone",
  "updatePrenotazioneOmbr": "https://www.singlefinapp.it/singlefin-ws/rest/ges/prenotazioneOmbrellone/updatePrenotazioneOmbrellone",

  "deletePrenotazioneOmbr": "https://www.singlefinapp.it/singlefin-ws/rest/ges/prenotazioneOmbrellone/deletePrenotazioneOmbrellone",
  "eliminaParzialmenteOmbr": "https://www.singlefinapp.it/singlefin-ws/rest//ges/prenotazioneOmbrellone/liberaParzialmentePrenotazioneOmbrellone",
  "setPrenotazioneSaldata": "https://www.singlefinapp.it/singlefin-ws/rest/ges/prenotazioneOmbrellone/setPrenotazioneOmbrelloneSaldata",
  "setPrenotazioneNonSaldata": "https://www.singlefinapp.it/singlefin-ws/rest/ges/prenotazioneOmbrellone/setPrenotazioneOmbrelloneNonSaldata",

  "getPrenotazioniRistorante": "https://www.singlefinapp.it/singlefin-ws/rest/ges/prenotazioneRistorante/getPrenotazioniRistoranteGiornoTurno?data=",
  "insertPrenotazioneRist": "https://www.singlefinapp.it/singlefin-ws/rest/ges/prenotazioneRistorante/createPrenotazioneRistorante",
  "deletePrenotazioneRist": "https://www.singlefinapp.it/singlefin-ws/rest/ges/prenotazioneRistorante/deletePrenotazioneRistorante",
  "updatePrenotazioneRist": "https://www.singlefinapp.it/singlefin-ws/rest/ges/prenotazioneRistorante/updatePrenotazioneRistorante",

  "getOrdiniStripe": "https://www.singlefinapp.it/singlefin-ws/rest/ges/ordine/getOrdiniGiornalieri",
  "getDataAggiornamentoOrdiniGiornalieri": "https://www.singlefinapp.it/singlefin-ws/rest/ges/ordine/getDataAggiornamentoOrdiniGiornalieri",

  "getOmbrDuplicati": "https://www.singlefinapp.it/singlefin-ws/rest/ges/prenotazioneOmbrellone/getDoppioni",

  "getUrlStreaming": "https://www.singlefinapp.it/singlefin-ws/rest/ges/parametro/getUrlStreaming",
  "setUrlStreaming": "https://www.singlefinapp.it/singlefin-ws/rest/ges/parametro/setUrlStreaming",

  "getOrdiniBarGiorno": "https://www.singlefinapp.it/singlefin-ws/rest/ges/ordineBar/getOrdiniBarGiorno?data=",
  "updateOrdineBar": "https://www.singlefinapp.it/singlefin-ws/rest/ges/ordineBar/updateOrdineBar",
  "updateOrdinePagato": "https://www.singlefinapp.it/singlefin-ws/rest/ges/ordine/updateOrdinePagato",
  "deleteOrdineBar": "https://www.singlefinapp.it/singlefin-ws/rest/ges/ordineBar/deleteOrdineBar",
  "getUtenteById": "https://www.singlefinapp.it/singlefin-ws/rest/ges/utente/getUtente",
  "isPagaCarta": "https://www.singlefinapp.it/singlefin-ws/rest/ges/parametro/getPagaSoloContanti",
  "setPagaCarta": "https://www.singlefinapp.it/singlefin-ws/rest/ges/parametro/setPagaSoloContanti",
  "isPagaContanti": "https://www.singlefinapp.it/singlefin-ws/rest/ges/parametro/getPagaAncheContanti",
  "setPagaContanti": "https://www.singlefinapp.it/singlefin-ws/rest/ges/parametro/setPagaAncheContanti",
  "isPaypalDisponibile": "https://www.singlefinapp.it/singlefin-ws/rest/ges/parametro/isPaypalDisponibile",
  "setPaypalDisponibile": "https://www.singlefinapp.it/singlefin-ws/rest/ges/parametro/setPaypalDisponibile",
  "isAdmin": "https://www.singlefinapp.it/singlefin-ws/rest/ges/utente/isAdmin",

  "getSdraioDisponibili": "https://www.singlefinapp.it/singlefin-ws/rest/ges/servizioOmbrellone/getServizioDisponibile?idServizio=1",
  "getNumeroSdraioDisponibili": "https://www.singlefinapp.it/singlefin-ws/rest/ges/servizioOmbrellone/getQuantitaServizioDisponibile?idTipoServizio=1",
  "setSdraioDisponibili": "https://www.singlefinapp.it/singlefin-ws/rest/ges/servizioOmbrellone/setServizioDisponibile",
  "valutaOrdine": "https://www.singlefinapp.it/singlefin-ws/rest/ordine/valutaOrdine"
}

