import { Http, Headers, RequestOptions } from '@angular/http';
import { LocalStorageService, LocalStorage } from 'angular-web-storage';
import { Component, OnInit, Injectable } from '@angular/core';
import { GlobalDataService } from './global-data.service';
import { serviziRest } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })



export class AuthService {
  serviziRest: any;

  prova: string = 'provaGlobale';
  constructor(public globalData: GlobalDataService, private http: Http, private storage: LocalStorageService) {

    var val = this.storage.get('token');
    var utente = this.storage.get('username');
    // console.log("sono dentro Auth e ho un token pari a: " + val);


    if (val != undefined && val != null && val != '' && utente != undefined && utente != null && utente != '') {
      this.globalData.isUserLogged = true;
      this.getDatiUtenteSessione();
    } else {
      this.globalData.isUserLogged = false;
    }
  }
  getDatiUtenteSessione() {


    var tokenSessione = this.storage.get('token');
    var utenteSessione = this.storage.get('username');

    // console.log(this.tokenValue);
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + tokenSessione);
    const requestOptions = new RequestOptions({ headers: headers });

    let postData = {
      "email": utenteSessione

    }


      this.serviziRest = serviziRest;



      //  this.http.post('http://ec2-52-47-164-20.eu-west-3.compute.amazonaws.com/sunset-ws/rest/utente/getUtente', postData, requestOptions)
      this.http.post(this.serviziRest.getUtente, postData, requestOptions)
        .subscribe(data => {
          let tempUserdata = data.json();
          this.globalData.loggedUser = data.json();
          this.globalData.datiUtente.nome = tempUserdata.nome;
          this.globalData.datiUtente.cognome = tempUserdata.cognome;
          this.globalData.datiUtente.telefono = tempUserdata.telefono;
          this.globalData.datiUtente.email = tempUserdata.email;
          this.globalData.datiUtente.fotoProfilo = tempUserdata.image;

          this.globalData.isUserLogged = true;

          // console.log("sono a inizio app e il valore username è: " + utenteSessione);



          if (tempUserdata.image != null && tempUserdata.image != undefined && tempUserdata.image != '') {
            this.globalData.fotoProfilo = tempUserdata.image;
          } else {
            this.globalData.fotoProfilo = '';
          }

          // console.log(this.globalData.datiUtente.fotoProfilo);

        }, error => {
            console.log(error.status);

          //  console.log(error);
        });

  }
}
