import { NgModule } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "./shared.module";

const routes: Routes = [
    // Regular Route
    { path: 'gest-ombrelloni', loadChildren: () => import('./gestione-ombrelloni/gestioneOmbrelloni.module').then(m => m.GestioneOmbrelloniModule) },
    { path: 'gest-abbonamenti', loadChildren: () => import('./gestione-ombrelloni/gestioneAbbonamenti.module').then(m => m.GestioneAbbonamentiModule) },
    { path: 'gest-abbonamenti-esercizi', loadChildren: () => import('./gestione-ombrelloni-esercizi/gestioneAbbonamentiEsercizi.module').then(m => m.GestioneAbbonamentiEserciziModule) },
    { path: 'gest-ombrelloni-esercizi', loadChildren: () => import('./gestione-ombrelloni-esercizi/gestioneOmbrelloniEsercizi.module').then(m => m.GestioneOmbrelloniEserciziModule) },
    { path: 'gest-duplicati', loadChildren: () => import('./gestione-ombrelloni/gestioneDuplicati.module').then(m => m.GestioneDuplicatiModule) },

];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule,
    ]
})
export class OmbrelloniModule { }