import { Pipe, PipeTransform } from "@angular/core";
import { GlobalDataService } from "../providers/global-data.service";

@Pipe({
    name: 'stringFilterAnagrafica'
})

export class StringFilterPipeAnagrafica implements PipeTransform {

    tempData: any[] = [];
 
     // variabile che conterra' l'oggetto filtrato nella barra di ricerca in Anagrafica Clienti

    constructor(public globalData: GlobalDataService) { }
    transform(items: any[], filter: string) {
        if (!filter || filter === '') {
            items = null;
            return items;
        }
        //console.log(items);
        for (let i = 0; i < items.length; i++) {
            if ((items[i].nome.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                || (items[i].cognome.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                || (items[i].email.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                || (items[i].telefono.indexOf(filter) !== -1)) {
                this.tempData.push(items[i]);
            }
        }

        this.globalData.itemsFiltrati = this.tempData;
        this.tempData = [];

        return this.globalData.itemsFiltrati;
    }
}