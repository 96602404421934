import { Directive, OnInit, HostListener, ElementRef, Renderer2, HostBinding } from '@angular/core';

@Directive({
  selector: '[appSelezioneDinamica]'
})
export class SelezioneDinamicaDirective implements OnInit {

  constructor(private renderer: Renderer2, private elRef: ElementRef) { }
  ngOnInit() {

  }

  @HostBinding('style.backgroundColor') backgroundColor: string = "#f5f5f5";
  // questo associa direttamente 


  @HostListener('mouseover') onMouseOver(eventData: Event) {
    this.backgroundColor = "#daf1ff";
  }
  @HostListener('mouseleave') onMouseLeave(eventData: Event) {
    this.backgroundColor = "#f5f5f5";
  }
}
