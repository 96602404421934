import 'rxjs/add/operator/map'
import { Component, OnInit } from '@angular/core';
import { GlobalDataService } from '../providers/global-data.service';
import { Validators, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Http, Headers, RequestOptions } from '@angular/http';
import { LocalStorageService, LocalStorage } from 'angular-web-storage';

import * as $AB from 'jquery';
import * as bootstrap from 'bootstrap';
import { serviziRest } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})




export class LoginComponent implements OnInit {

  dataCorrente: any;
  utenteLoggato: boolean = false;
  token: any;
  loginError: boolean = false;
  closeResult: string;
  serviziRest: any;

  loginForm: FormGroup;
  userData = { "password": "", "email": "", "nome": "", "cognome": "", "telefono": "", "fotoProfilo": "" };


  constructor(public globalData: GlobalDataService, private http: Http, private storage: LocalStorageService) {


    this.utenteLoggato = globalData.isUserLogged;


  }

  ngOnInit() {
    let emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    this.loginForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(12)]),
      email: new FormControl('', [Validators.required, Validators.pattern(emailPattern)])
    });

  }

  home() {
    this.userData.email = this.loginForm.get('email').value;
    this.userData.password = this.loginForm.get('password').value;
    // qui chiamata ajax in post con utente e password, salvati come identifier e secret, per veriricare che l'utente sia già nel d    
    this.loginManuale(this.userData);

  }

  showTimeStamp() {
    let dataOggi = new Date();
    let gg = dataOggi.getDate();
    let mm = dataOggi.getMonth() + 1;

    var aa = dataOggi.getFullYear();
    if (gg < 10) {

      //gg = "0" + gg;
    }
    if (mm < 10) {
      //   mm = "0" + mm;
    }

    this.dataCorrente = gg + "-" + mm + "-" + aa;
    //console.log(this.dataCorrente);

  }

  loadDatiBar() {
    var datiBar = "pippo";


  }

  loginManuale(userData) {

    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    const requestOptions = new RequestOptions({ headers: headers });

    let postData = {
      "utente": {
        "nome": '',
        "cognome": '',
        "email": userData.email,
        "telefono": ''
      },
      "credentials": {
        "secret": userData.password
      }

    }

    this.http.post(this.globalData.serviziRest.loginManuale, postData, requestOptions)
      .subscribe(data => {
        //  console.log(data['_body']);
        //  console.log(data.status);
        this.token = data['_body'];

        this.storage.set('token', this.token);
        this.storage.set('username', userData.email);
        //  console.log("sono a inizio if e ho un token pari a: " + this.storage.get('token') + " e utente " + this.storage.get('username'));

        this.globalData.tokenValue = this.token;
        this.globalData.isUserLogged = true;
        this.utenteLoggato = this.globalData.isUserLogged;
        // this.storage.set('token', this.token).then((val) => {

        //   console.log("Stato Token Dentro Login: " + val);
        //   console.log(userData);

        this.getDatiUtente(userData);
        this.globalData.getIsAdmin();
        this.globalData.getRuoloUtente();
        //   console.log(this.globalData.datiUtente);

        // });

        // this.storage.set('username', userData.email).then((val) => {

        // });


      }, error => {
        this.loginError = true;
        // this.globalData.getIsAdmin();
        //this.globalData.getRuoloUtente();
        this.userData.email = '';
        this.userData.password = '';
        this.loginForm.reset();

        alert("Nome utente o password errati");
        // console.log(error.status);


      });


  }

  getDatiUtenteSessione() {


    var tokenSessione = this.storage.get('token');
    var utenteSessione = this.storage.get('username');

    // console.log(this.tokenValue);
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + tokenSessione);
    const requestOptions = new RequestOptions({ headers: headers });

    let postData = {
      "email": utenteSessione

    }


    this.serviziRest = serviziRest;

    //  this.http.post('http://ec2-52-47-164-20.eu-west-3.compute.amazonaws.com/sunset-ws/rest/utente/getUtente', postData, requestOptions)
    this.http.post(this.serviziRest.getUtente, postData, requestOptions)
      .subscribe(data => {
        let tempUserdata = data.json();
        this.globalData.loggedUser = data.json();
        console.log(this.globalData.loggedUser);
        this.globalData.datiUtente.nome = tempUserdata.nome;
        this.globalData.datiUtente.cognome = tempUserdata.cognome;
        this.globalData.datiUtente.telefono = tempUserdata.telefono;
        this.globalData.datiUtente.email = tempUserdata.email;
        this.globalData.datiUtente.fotoProfilo = tempUserdata.image;

        this.globalData.isUserLogged = true;

        //   console.log("sono dentro get utente sessione e ho un token pari a: " + tokenSessione + " e utente " + utenteSessione);

        this.utenteLoggato = this.globalData.isUserLogged;


        if (tempUserdata.image != null && tempUserdata.image != undefined && tempUserdata.image != '') {
          this.globalData.fotoProfilo = tempUserdata.image;
        } else {
          this.globalData.fotoProfilo = '';
        }

        // console.log(this.globalData.datiUtente.fotoProfilo);

      }, error => {
        // console.log(error.status);

        // console.log(error);
      });

  }

  getDatiUtente(userData) {

    // console.log(this.tokenValue);
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.globalData.tokenValue);
    const requestOptions = new RequestOptions({ headers: headers });

    let postData = {
      "email": userData.email

    }
    //console.log("sono dentro get utente  e ho un token pari a: " + this.globalData.tokenValue + " e utente " + userData.email);

    this.http.post(this.globalData.serviziRest.getUtente, postData, requestOptions)
      .subscribe(data => {
        let tempUserdata = data.json();
        this.globalData.loggedUser = data.json();
        this.globalData.datiUtente.nome = tempUserdata.nome;
        this.globalData.datiUtente.cognome = tempUserdata.cognome;
        this.globalData.datiUtente.telefono = tempUserdata.telefono;
        this.globalData.datiUtente.email = tempUserdata.email;
        this.globalData.datiUtente.fotoProfilo = tempUserdata.image;
        if (tempUserdata.image != null && tempUserdata.image != undefined && tempUserdata.image != '') {
          this.globalData.fotoProfilo = tempUserdata.image;
        } else {
          this.globalData.fotoProfilo = '';
        }

        // console.log(this.globalData.datiUtente.fotoProfilo);

      }, error => {
        // console.log(error.status);

        // console.log(error);
      });
  }
}
