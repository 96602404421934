import { NgModule } from "@angular/core";
import { StringFilterPipe } from './pipes/filter.pipe';
import { StringFilterPipeOrdini } from './pipes/filterOrdini.pipe';
import { StringFilterPipeOrdiniMobile } from './pipes/filterOrdiniMobile.pipe';
import { StringFilterPipeAnagrafica } from './pipes/filterAnagrafica.pipe';
import { StringFilterPipeOrdiniBar } from './pipes/filterOrdiniBar.pipe';
import { DatePickerModule } from "./datepicker.module";
import { NgxPaginationModule } from 'ngx-pagination';
import { AlertModule } from 'ngx-bootstrap/alert';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
/* import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner'; */
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [

        StringFilterPipe,
        StringFilterPipeOrdini,
        StringFilterPipeOrdiniMobile,
        StringFilterPipeAnagrafica,
        StringFilterPipeOrdiniBar
    ],
    imports: [
        AlertModule.forRoot(),
        DatePickerModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    exports: [
        StringFilterPipe,
        NgxPaginationModule,
        AlertModule,
        ReactiveFormsModule,
        FormsModule,
        /*         Ng4LoadingSpinnerModule, */
        BsDropdownModule,
        NgbModule,
        DatePickerModule,
        ModalModule,
        TooltipModule,
        StringFilterPipeOrdini,
        StringFilterPipeOrdiniMobile,
        StringFilterPipeAnagrafica,
        StringFilterPipeOrdiniBar
    ]
})

export class SharedModule { }