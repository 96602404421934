import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map'
import { Http, Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { LocalStorageService, LocalStorage } from 'angular-web-storage';
import * as $AB from 'jquery';
import * as bootstrap from 'bootstrap';
/* import { Ng4LoadingSpinnerModule, Ng4LoadingSpinnerService } from 'ng4-loading-spinner'; */
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { GlobalFuncService } from './global-func.service';
import { serviziRest } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {

  totaleCopertiPranzo: number = 0;
  totaleCopertiCena: number = 0;

  datUltimoAggiornamento: any = 0;
  riepilogoIncassi: any = null;
  riepilogoIncassoAnnoCorrente = { totale: 0, totaleStripeOmbrelloni: 0, totalePayPalOmbrelloni: 0, totaleBraintreeOmbrelloni: 0, totaleContantiOmbrelloni: 0, totaleStripeBar: 0, totalePayPalBar: 0, totaleBraintreeBar: 0, totaleContantiBar: 0 };
  riepilogoIncassoMeseCorrente = { totale: 0, totaleStripeOmbrelloni: 0, totalePayPalOmbrelloni: 0, totaleBraintreeOmbrelloni: 0, totaleContantiOmbrelloni: 0, totaleStripeBar: 0, totalePayPalBar: 0, totaleBraintreeBar: 0, totaleContantiBar: 0 };
  riepilogoIncassoGiornoCorrente = { totale: 0, totaleStripeOmbrelloni: 0, totalePayPalOmbrelloni: 0, totaleBraintreeOmbrelloni: 0, totaleContantiOmbrelloni: 0, totaleStripeBar: 0, totalePayPalBar: 0, totaleBraintreeBar: 0, totaleContantiBar: 0, totaleBar: 0, totaleOmbrelloni: 0 };
  loggedUser: any;
  listaUtentiRivenditori: any;
  fasciaOrariaSelezionata: any = "";
  stringaFasciaOraria: any = "Intera Giornata";
  ultimaDataLettaRiepilogoORdini: any = "";
  isFirstRiepilogoFetch: boolean = true;
  orarioRistorante: { hour: number, minute: number };
  itemsFiltrati: any; // contiene il risultato della barra di ricerca in inserimento ombrellonbe
  isUserLogged: boolean;
  serviziRest: any; // qui metter� tutte le chiamate ai servizi, così da poterli avere tutti in un unico file di properties.
  datiUtente = { "password": "", "email": "", "nome": "", "cognome": "", "telefono": "", "fotoProfilo": "" };
  tokenValue: any;
  fotoProfilo: any;
  datePicker = { dataSelezionata: { 'year': '', 'month': '', 'day': '' } };
  datePickerFinoA = { dataSelezionataFinoA: { 'day': '', 'month': '', 'year': '' } };
  datePickerEditRist = { dataEditRistorante: { 'year': '', 'month': '', 'day': '' } };

  showDeleteAllButton: boolean = false;

  /* Qui inserisco gli utenti da abilitare per le varie funzioni */
  isAdminLogged: boolean = false; // idRuoloUtente 1
  isGestoreBarLogged: boolean = false; // idRuoloUtente 5
  isRivenditoreServizi: boolean = false; // esercente rivenditore servizi 6
  isSuperAdmin: boolean = false; // super admin ruolo 100
  isBagninoLogged: boolean = false; // idRuoloUtente 4
  /* Fine Funzioni per idRuoloUtente */


  totaleUtentiRegistrati: any = 0;
  urlStreaming: any = "";
  storicoOrdiniData: any; // qui legge il servizio Rest per ottenere lo storico ordini
  jsonStoricoOrdini: any[]; // questo è il json ottenuto dal rest per lo storico ordini
  dataScelta: any; // data selezionata relativa allo storico ordini
  dataSceltaFormatoIta: any = ''; // data selezionata nel formato italiano relativa allo storico ordini, appare nella pagina html Storico Ordini
  dataSceltaFinoAFormatoIta: any = ''; // data selezionata nel formato italiano relativa allo storico ordini, appare nella pagina html Storico Ordini
  localDataOmbr: any;
  dispOmbrelloni: any;
  totaleOmbrLiberi: any = 0;
  totaleSdraioLibere: any = 0;
  totaleOmbrPrenotati: any = 0;
  totaleIncassoGiornaliero: any = 0;
  totaleOmbrelloniGiornalieri: any = 0;
  ombrelloniDuplicati: any = [];
  localDataRist: any;
  showPaginaAbbonamenti: boolean = false;

  prenotazioniRistPranzo: any;
  prenotazioniRistCena: any;
  postiDisponibiliPranzo: any;
  postiDisponibiliCena: any;

  showAlertNewBookingsBar: boolean = false;
  dataUltimoOrdineBar: number = 0;

  showAlertNewBookingsRist: boolean = false;
  dataUltimoOrdineRist: number = 0;
  datiStoricoOrdini: any;


  dataInizio = ""; // date per gestione abbonamenti
  dataInizioToShow = ""; // date per gestione abbonamenti
  dataFine = "";
  dataFineToShow = "";

  listaCani = [];
  listaServiziPrenotati = [];


  modificaInCorso: boolean = false; // se true, non faccio refresh dei dati durante esecuzione dell'app, significa che ho qualcuno che sta apportando modifiche ai dati.

  /* FLAG PER FILTRI DI RICERCA */

  filtraOrdiniAnnulati: boolean = false;
  filtraOrdiniMattina: boolean = false;
  filtraOrdiniPomeriggio: boolean = false;

  /* FINE FLAG PER FILTRI DI RICERCA */


  /* Campi che mi servono per gstire i datepicker dentro la sezione ristorante*/

  dataAddRistorante: any; //qui ho dovuto usare queste due variabili per implementare il datepicker in italiano
  dataDispRistorante: any; // serve al popup per cambiare la disponibilità posti in una certa data
  dataEditRistorante: any; // ma per usarlo dovevo dichiararlo fuori e adoperare delle variabili globali 
  ristoranteEditForm: FormGroup;
  ristoranteAddForm: FormGroup;
  ristoranteDispForm: FormGroup;
  /****************************/

  listaUtenti: any; // conterrà la lista utenti
  listaUtentiData: any; // risposta della chiamata al servizio per ottenere la lista utenti
  itemsOrdiniBar: any;

  constructor(/* private spinner: Ng4LoadingSpinnerService, public ng4LoadingSpinnerService: Ng4LoadingSpinnerService */ private http: Http, private router: Router, private storage: LocalStorageService) {
    this.initServiziRest();

  }

  resolveAfter2Ms() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
        this.serviziRest = serviziRest;
        this.dataAddRistorante = "";
        this.dataEditRistorante = "";
        this.dataUltimoOrdineBar = 0;
        this.getIsAdmin();
        this.getRuoloUtente();

        if (this.storage.get('token') != '' && this.storage.get('token') != null && this.storage.get('token') != undefined) {
          this.tokenValue = this.storage.get('token');
        }
      }, 150);
    });
  }

  async initServiziRest() {
    await this.resolveAfter2Ms();
  }

  onFasciaOrariaChange() {
    if (this.fasciaOrariaSelezionata == 1) {
      this.stringaFasciaOraria = 'Intera Giornata';
    } else if (this.fasciaOrariaSelezionata == 2) {
      this.stringaFasciaOraria = 'Mattina';
    } else if (this.fasciaOrariaSelezionata == 3) {
      this.stringaFasciaOraria = 'Pomeriggio';
    } else if (this.fasciaOrariaSelezionata == '' || this.fasciaOrariaSelezionata == null || this.fasciaOrariaSelezionata == undefined) {
      this.stringaFasciaOraria = '';
    }

    if (this.datePicker.dataSelezionata != null) {
      //  console.log("sono nel primo caso");
      this.aggiornaCalendarioOmbrelloni(this.datePicker.dataSelezionata);
    } else {
      // console.log("sono nel secondo");
      let dataOdierna = this.creaDataOdierna();
      this.aggiornaCalendarioOmbrelloni(dataOdierna);
    }
  }



  checkShowButtoneDeleteAll(oggi, dataScelta) {
    if (oggi == dataScelta) {
      this.showDeleteAllButton = true;
    } else {
      this.showDeleteAllButton = false;
    }
  }


  setDatiUtente() {

    /* this.ng4LoadingSpinnerService.show(); */
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.tokenValue);
    const requestOptions = new RequestOptions({ headers: headers });

    let postData = {
      //    "email": datiUtente.email,
      //      "password" : "",
      "nome": this.datiUtente.nome,
      "cognome": this.datiUtente.cognome,
      "telefono": this.datiUtente.telefono,
      "image": this.datiUtente.fotoProfilo

    }


    this.http.post(this.serviziRest.setUtente, postData, requestOptions)
      .subscribe(data => {
        // console.log(data);
        // console.log("tutto ok aggiornamento dati profilo");
        this.fotoProfilo = this.datiUtente.fotoProfilo;
        this.getDatiUtente(this.datiUtente.email);
        setTimeout(function () {
          /*            */
        }.bind(this), 1000);

      }, error => {
        /*          */
        // console.log(error.status);
        if (error.status == 0) {

        } else if (error.status == 400 || error.status == 401 || error.status == 403) {

        } else {

        }

        //  console.log(error);
      });


  }

  aggiornaStorico(datePicker) {  // serve ad aggiornare lo storico ordini selezionando una data dal calendario
    console.log("sono in aggiorna storico");
    /* this.ng4LoadingSpinnerService.show(); */
    if (datePicker.year != '' && datePicker.year != undefined && datePicker.year != '--') {
      /*    this.dataScelta = datePicker.year + "-" + datePicker.month + "-" + datePicker.day; */
      this.dataScelta = datePicker.year + "-" + ((datePicker.month < 10) ? ("0" + datePicker.month) : datePicker.month) + "-" + ((datePicker.day < 10) ? ("0" + datePicker.day) : datePicker.day);
      var gg = (datePicker.day * 1);
      var mm = (datePicker.month * 1);
      console.log(this.dataScelta);
      this.dataSceltaFormatoIta = ((gg < 10) ? ("0" + gg) : datePicker.day) + "-" + ((mm < 10) ? ("0" + mm) : datePicker.month) + "-" + datePicker.year;
      /*    setTimeout(function() {
           
         }.bind(this), 1000); */
      this.getDatiRiepilogo(this.dataScelta);
      this.getDisponibilitaOmbrelloniData(this.dataScelta, this.dataScelta, this.fasciaOrariaSelezionata, 'da global data dentro aggiorna storico');
    }
  }

  aggiornaDatiRiepilogo(datePicker) {  // serve ad aggiornare lo storico ordini selezionando una data dal calendario
    if (datePicker.year != '' && datePicker.year != undefined && datePicker.year != '--') {
      /*       this.dataScelta = datePicker.year + "-" + datePicker.month + "-" + datePicker.day; */
      this.dataScelta = datePicker.year + "-" + ((datePicker.month < 10) ? ("0" + datePicker.month) : datePicker.month) + "-" + ((datePicker.day < 10) ? ("0" + datePicker.day) : datePicker.day);
      var gg = (datePicker.day * 1);
      var mm = (datePicker.month * 1);
      this.dataSceltaFormatoIta = ((gg < 10) ? ("0" + gg) : datePicker.day) + "-" + ((mm < 10) ? ("0" + mm) : datePicker.month) + "-" + datePicker.year;

    } else {
      var d = new Date();
      var mese = '' + (d.getMonth() + 1);
      var giorno = '' + d.getDate();
      var anno = d.getFullYear();
      if (mese.length < 2) mese = '0' + mese;
      if (giorno.length < 2) giorno = '0' + giorno;

      this.datePicker.dataSelezionata.year = anno + "";
      this.datePicker.dataSelezionata.day = giorno + "";
      this.datePicker.dataSelezionata.month = mese + "";
      this.dataSceltaFormatoIta = giorno + "-" + mese + "-" + anno;
      this.dataScelta = anno + "-" + mese + "-" + giorno;
    }
    if (this.ultimaDataLettaRiepilogoORdini == '' || this.ultimaDataLettaRiepilogoORdini != this.dataScelta) {
      this.isFirstRiepilogoFetch = true;
      this.datUltimoAggiornamento = 0;
    } else {
      this.isFirstRiepilogoFetch = false;
    }
    this.ultimaDataLettaRiepilogoORdini = this.dataScelta;

    this.getDataUltimoAggiornamento(this.dataScelta);
  }


  getDatiRiepilogo(dataRicevuta) {  // fa la chiamata per ottenere la disponibilità ristorante in una certa data 
    // this.datiStoricoOrdini = [];
    this.listaCani = [];
    this.listaServiziPrenotati = [];
    this.totaleOmbrPrenotati = 0;
    this.totaleIncassoGiornaliero = 0;
    this.totaleOmbrelloniGiornalieri = 0;


    let dataDaModificare = null;
    dataDaModificare = dataRicevuta.split("-");
    let mese = '' + dataDaModificare[1];
    let giorno = '' + dataDaModificare[2];
    let anno = '' + dataDaModificare[0];

    if (mese.length < 2) mese = '0' + mese;
    if (giorno.length < 2) giorno = '0' + giorno;
    let dataModificata = anno + "-" + mese + "-" + giorno;
    let dataModificataItaliana = giorno + "/" + mese + "/" + anno;


    if (dataRicevuta == undefined || dataRicevuta == null || dataRicevuta == '') {
      dataRicevuta = this.creaData();
    }

    this.tokenValue = this.storage.get('token');


    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.tokenValue);

    const requestOptions = new RequestOptions({ headers: headers });
    if (this.isFirstRiepilogoFetch) {
      /* this.ng4LoadingSpinnerService.show(); */
    }
    this.http.get(this.serviziRest.getOrdiniStripe + "?data=" + dataModificata, requestOptions).subscribe(data => {

      if (data != null && data['_body'] != '' && data != undefined) {
        this.isFirstRiepilogoFetch = false;
        this.datiStoricoOrdini = data.json();
        // console.log(this.datiStoricoOrdini);
        let dataGiornoPrenotatoTrimmato = "";
        let dataDaControllare = "";
        for (let i = 0; i < this.datiStoricoOrdini.length; i++) {

          for (let j = 0; j < this.datiStoricoOrdini[i].ombrelloni.length; j++) {
            this.totaleOmbrPrenotati++;
            if (this.datiStoricoOrdini[i].ombrelloni[j].ombrellone.labelFila.indexOf('Umani') !== -1) {
              this.datiStoricoOrdini[i].ombrelloni[j].ombrellone.posizionePrenotazione = 'Umani';
            } else {
              this.datiStoricoOrdini[i].ombrelloni[j].ombrellone.posizionePrenotazione = 'Cani';
            }
            for (let k = 0; k < this.datiStoricoOrdini[i].ombrelloni[j].listaCani.length; k++) {
              this.listaCani.push({ 'nome': this.datiStoricoOrdini[i].ombrelloni[j].listaCani[k].nome, 'taglia': this.datiStoricoOrdini[i].ombrelloni[j].listaCani[k].taglia.labelTaglia });

            }
            for (let z = 0; z < this.datiStoricoOrdini[i].ombrelloni[j].serviziPrenotatis.length; z++) {
              if (this.datiStoricoOrdini[i].ombrelloni[j].serviziPrenotatis[z].quantita != 0) {

                this.listaServiziPrenotati.push({
                  'idServizio': this.datiStoricoOrdini[i].ombrelloni[j].serviziPrenotatis[z].idServizio,
                  'quantita': this.datiStoricoOrdini[i].ombrelloni[j].serviziPrenotatis[z].quantita
                });
              }
            }



            let fasciaOraria = this.calcolaFasciaOraria(this.datiStoricoOrdini[i].ombrelloni[j].idFasciaOraria);
            var utcSecondsDataDiPrenotazione = this.datiStoricoOrdini[i].ombrelloni[j].dataPrenotazione;
            var utcSecondsGiornoPrenotato = this.datiStoricoOrdini[i].ombrelloni[j].dataInizio;
            var utcSecondsGiornoPrenotatoFine = this.datiStoricoOrdini[i].ombrelloni[j].dataFine;
            utcSecondsDataDiPrenotazione -= 7200;
            utcSecondsGiornoPrenotato -= 7200;
            utcSecondsGiornoPrenotato -= 7200;

            var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
            var dFine = new Date(0); // The 0 there is the key, which sets the date to the epoch
            var dd = new Date(0); // The 0 there is the key, which sets the date to the epoch
            dd.setUTCMilliseconds(utcSecondsDataDiPrenotazione);
            d.setUTCMilliseconds(utcSecondsGiornoPrenotato);
            dFine.setUTCMilliseconds(utcSecondsGiornoPrenotatoFine);

            let month = "" + (d.getMonth() + 1);
            let day = "" + d.getDate();

            let monthFine = "" + (dFine.getMonth() + 1);
            let dayFine = "" + dFine.getDate();

            let ora = "" + dd.getHours();
            let minuti = "" + dd.getMinutes();

            if (month.length < 2) {
              month = '0' + month;
            }
            if (day.length < 2) {
              day = '0' + day;
            }

            if (monthFine.length < 2) {
              monthFine = '0' + monthFine;
            }
            if (dayFine.length < 2) {
              dayFine = '0' + dayFine;
            }

            if (minuti.length < 2) {
              minuti = '0' + minuti;
            }

            if (day.length < 2) {
              day = '0' + day;
            }

            if (month.length < 2) {
              month = '0' + month;
            }
            if (day.length < 2) {
              day = '0' + day;
            }

            let monthD = "" + (dd.getMonth() + 1);
            let dayD = "" + dd.getDate();
            if (monthD.length < 2) {
              monthD = '0' + monthD;
            }
            if (dayD.length < 2) {
              dayD = '0' + dayD;
            }

            this.datiStoricoOrdini[i].ombrelloni[j].dataGiornoInserimentoPrenotazione = dayD + "/" + monthD + "/" + dd.getFullYear() + " " + ora + ":" + minuti;
            this.datiStoricoOrdini[i].ombrelloni[j].dataGiornoInserimentoPrenotazioneTrimmata = dayD + "/" + monthD + "/" + dd.getFullYear();
            this.datiStoricoOrdini[i].ombrelloni[j].dataPrenotazioneInizio = day + "/" + month + "/" + d.getFullYear();
            this.datiStoricoOrdini[i].ombrelloni[j].dataPrenotazioneFine = dayFine + "/" + monthFine + "/" + dFine.getFullYear();
            this.datiStoricoOrdini[i].ombrelloni[j].dataDaControllare = d.getFullYear() + "-" + month + "-" + day;
            this.datiStoricoOrdini[i].ombrelloni[j].dataModificata = dataModificata;
            this.datiStoricoOrdini[i].ombrelloni[j].dataModificataItaliana = dataModificataItaliana;
            this.datiStoricoOrdini[i].ombrelloni[j].fasciaOraria = fasciaOraria;
            this.datiStoricoOrdini[i].ombrelloni[j].listaCani = this.listaCani;
            this.datiStoricoOrdini[i].ombrelloni[j].listaServiziPrenotati = this.listaServiziPrenotati;
            //  console.log( this.datiStoricoOrdini[i].ombrelloni[j].listaCani);
            this.listaCani = [];
            this.listaServiziPrenotati = [];
            // console.log(this.datiStoricoOrdini[i].ombrelloni[j].dataPrenotazioneStripe);
            /*   if (dataModificata == this.datiStoricoOrdini[i].ombrelloni[j].dataDaControllare) {
                this.totaleIncassoGiornaliero += this.datiStoricoOrdini[i].ombrelloni[j].costo;
                this.totaleOmbrelloniGiornalieri++;
              } */

          }
        }

        //    console.log(this.datiStoricoOrdini);
      } else {
        this.datiStoricoOrdini = [];
      }

      /*        */

    }, error => {
      if (error.status == 0) {
        /*          */
      } else {

      }

    });

  }



  getDataUltimoAggiornamento(dataRicevuta) {

    let dataDaModificare = null;
    dataDaModificare = dataRicevuta.split("-");
    let mese = '' + dataDaModificare[1];
    let giorno = '' + dataDaModificare[2];
    let anno = '' + dataDaModificare[0];

    if (mese.length < 2) mese = '0' + mese;
    if (giorno.length < 2) giorno = '0' + giorno;
    let dataModificata = anno + "-" + mese + "-" + giorno;


    if (dataRicevuta == undefined || dataRicevuta == null || dataRicevuta == '') {
      dataRicevuta = this.creaData();
    }
    this.tokenValue = this.storage.get('token');


    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.tokenValue);

    const requestOptions = new RequestOptions({ headers: headers });
    if (this.isFirstRiepilogoFetch) {
      /*       this.spinner.show(); */
    }
    this.http.get(this.serviziRest.getDataAggiornamentoOrdiniGiornalieri + "?data=" + dataModificata, requestOptions).subscribe(data => {
      /* console.log(data['_body']);
      console.log(this.datUltimoAggiornamento); */
      if (data['_body'] > this.datUltimoAggiornamento) {
        this.getDatiRiepilogo(dataRicevuta);
      }
      this.datUltimoAggiornamento = data['_body'];
      /*       this.spinner.hide(); */
    });
  }



  calcolaFasciaOraria(fasciaOraria) {
    if (fasciaOraria == 1) {
      return "Intera Giornata";
    } else if (fasciaOraria = 2) {
      return "Mattina";
    } else if (fasciaOraria = 3) {
      return "Pomeriggio";
    } else {
      return "Fascia Oraria non specificata";
    }
  }


  getOmbrDuplicati() {



    this.tokenValue = this.storage.get('token');

    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.tokenValue);
    const requestOptions = new RequestOptions({ headers: headers });

    /*     this.spinner.show(); */

    this.http.get(this.serviziRest.getOmbrDuplicati, requestOptions).subscribe(data => {
      this.ombrelloniDuplicati = data.json();

      // console.log(this.ombrelloniDuplicati);
      for (let i = 0; i < this.ombrelloniDuplicati.length; i++) {

        var utcSecondsDataDiPrenotazioneUno = this.ombrelloniDuplicati[i].prenotazione1.dataPrenotazione;
        var utcSecondsGiornoPrenotatoUno = this.ombrelloniDuplicati[i].prenotazione1.dataInizio;
        var utcSecondsDataDiPrenotazioneDue = this.ombrelloniDuplicati[i].prenotazione2.dataPrenotazione;
        var utcSecondsGiornoPrenotatoDue = this.ombrelloniDuplicati[i].prenotazione2.dataInizio;

        utcSecondsDataDiPrenotazioneUno -= 7200;
        utcSecondsGiornoPrenotatoUno -= 7200;
        utcSecondsDataDiPrenotazioneDue -= 7200;
        utcSecondsGiornoPrenotatoDue -= 7200;


        var d = new Date(0);
        var dd = new Date(0);

        var d_Due = new Date(0);
        var dd_Due = new Date(0);

        dd.setUTCMilliseconds(utcSecondsDataDiPrenotazioneUno);
        d.setUTCMilliseconds(utcSecondsGiornoPrenotatoUno);

        dd_Due.setUTCMilliseconds(utcSecondsDataDiPrenotazioneDue);
        d_Due.setUTCMilliseconds(utcSecondsGiornoPrenotatoDue);

        let month = "" + (d.getMonth() + 1);
        let day = "" + d.getDate();
        let ora = "" + dd.getHours();
        let minuti = "" + dd.getMinutes();

        let month_Due = "" + (d_Due.getMonth() + 1);
        let day_Due = "" + d_Due.getDate();
        let ora_Due = "" + dd_Due.getHours();
        let minuti_Due = "" + dd_Due.getMinutes();

        // Prenotazione 1

        if (month.length < 2) {
          month = '0' + month;
        }
        if (day.length < 2) {
          day = '0' + day;
        }

        if (minuti.length < 2) {
          minuti = '0' + minuti;
        }

        if (ora.length < 2) {
          ora = '0' + ora;
        }

        // Prenotazione 2

        if (month_Due.length < 2) {
          month_Due = '0' + month_Due;
        }
        if (day_Due.length < 2) {
          day_Due = '0' + day_Due;
        }

        if (minuti_Due.length < 2) {
          minuti_Due = '0' + minuti_Due;
        }

        if (ora_Due.length < 2) {
          ora_Due = '0' + ora_Due;
        }

        let monthD = "" + (dd.getMonth() + 1);
        let dayD = "" + dd.getDate();

        let monthD_Due = "" + (dd_Due.getMonth() + 1);
        let dayD_Due = "" + dd_Due.getDate();



        if (monthD.length < 2) {
          monthD = '0' + monthD;
        }
        if (dayD.length < 2) {
          dayD = '0' + dayD;
        }

        if (monthD_Due.length < 2) {
          monthD_Due = '0' + monthD_Due;
        }
        if (dayD_Due.length < 2) {
          dayD_Due = '0' + dayD_Due;
        }

        this.ombrelloniDuplicati[i].prenotazione1.dataGiornoPrenotato = dayD + "/" + monthD + "/" + dd.getFullYear() + "  " + ora + ":" + minuti;
        this.ombrelloniDuplicati[i].prenotazione1.dataPrenotazioneStripe = day + "/" + month + "/" + d.getFullYear();
        this.ombrelloniDuplicati[i].prenotazione2.dataGiornoPrenotato = dayD_Due + "/" + monthD_Due + "/" + dd_Due.getFullYear() + "  " + ora_Due + ":" + minuti_Due;
        this.ombrelloniDuplicati[i].prenotazione2.dataPrenotazioneStripe = day_Due + "/" + month_Due + "/" + d_Due.getFullYear();

      }



      setTimeout(function () {
        //this.getSdraioDisponibili(dataRicevuta);
        this.spinner.hide();
      }.bind(this), 1500);

    }, error => {
      setTimeout(function () {
        //this.getSdraioDisponibili(dataRicevuta);
        this.spinner.hide();
      }.bind(this), 1500);
      if (error.status == 0) {
      } else {
      }

    });

  }


  getUrlStreaming() {

    this.tokenValue = this.storage.get('token');

    var headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.tokenValue);
    const requestOptions = new RequestOptions({ headers: headers });

    this.http.get(this.serviziRest.getUrlStreaming, requestOptions).subscribe(data => {

      this.urlStreaming = data['_body'];
      // console.log(this.urlStreaming);
    }, error => {
      if (error.status == 0) {

      } else {

      }

    });

  }

  creaDataOdierna() {
    var d = new Date();
    var mese = '' + (d.getMonth() + 1);
    var giorno = '' + d.getDate();
    var anno = d.getFullYear();
    if (mese.length < 2) mese = '0' + mese;
    if (giorno.length < 2) giorno = '0' + giorno;

    return giorno + "-" + mese + "-" + anno;
  }

  aggiornaCalendarioOmbrelloni(dataSelezionata) {  // serve ad aggiornare lo storico ordini selezionando una data dal calendario
    // console.log("sono dentro aggiornaCalendarioOmbrelloni");
    // console.log(this.datePicker);
    if (dataSelezionata != null) {
      if (dataSelezionata.year != '' && dataSelezionata.year != undefined && dataSelezionata.year != '--') {
        this.dataScelta = dataSelezionata.year + "-" + dataSelezionata.month + "-" + dataSelezionata.day;

        var gg = (dataSelezionata.day * 1);
        var mm = (dataSelezionata.month * 1);
        this.dataSceltaFormatoIta = ((gg < 10) ? ("0" + gg) : dataSelezionata.day) + "-" + ((mm < 10) ? ("0" + mm) : dataSelezionata.month) + "-" + dataSelezionata.year;
        this.dataScelta = dataSelezionata.year + "-" + ((dataSelezionata.month < 10) ? ("0" + dataSelezionata.month) : dataSelezionata.month) + "-" + ((dataSelezionata.day < 10) ? ("0" + dataSelezionata.day) : dataSelezionata.day);
      }
    } else {
      var d = new Date();
      var mese = '' + (d.getMonth() + 1);
      var giorno = '' + d.getDate();
      var anno = d.getFullYear();
      if (mese.length < 2) mese = '0' + mese;
      if (giorno.length < 2) giorno = '0' + giorno;

      this.datePicker.dataSelezionata.year = anno + "";
      this.datePicker.dataSelezionata.day = giorno + "";
      this.datePicker.dataSelezionata.month = mese + "";
      this.dataSceltaFormatoIta = giorno + "-" + mese + "-" + anno;
      this.dataScelta = anno + "-" + mese + "-" + giorno;
    }
    /* this.ng4LoadingSpinnerService.show(); */
    // console.log("sto aggiornado il calendario ombrelloni con i seguenti dati: " + 'dataInizio ' + this.dataInizio + 'dataFine ' + this.dataFine + 'fasciaOraria' + this.fasciaOrariaSelezionata);

    this.getDisponibilitaOmbrelloniData(this.dataScelta, this.dataScelta, this.fasciaOrariaSelezionata, 'da globaldata dentro aggiorna calendario');

    //console.log(this.dataScelta);

  }


  aggiornaCalendarioRistorante(datePicker) {  // serve ad aggiornare lo storico ordini selezionando una data dal calendario
    if (datePicker.year != '' && datePicker.year != undefined && datePicker.year != '--') {
      this.dataScelta = datePicker.year + "-" + datePicker.month + "-" + datePicker.day;
      var gg = (datePicker.day * 1);
      var mm = (datePicker.month * 1);
      this.dataSceltaFormatoIta = ((gg < 10) ? ("0" + gg) : datePicker.day) + "-" + ((mm < 10) ? ("0" + mm) : datePicker.month) + "-" + datePicker.year;
      this.dataScelta = datePicker.year + "-" + ((datePicker.month < 10) ? ("0" + datePicker.month) : datePicker.month) + "-" + ((datePicker.day < 10) ? ("0" + datePicker.day) : datePicker.day);
    } else {
      var d = new Date();
      var mese = '' + (d.getMonth() + 1);
      var giorno = '' + d.getDate();
      var anno = d.getFullYear();
      if (mese.length < 2) mese = '0' + mese;
      if (giorno.length < 2) giorno = '0' + giorno;

      this.datePicker.dataSelezionata.year = anno + "";
      this.datePicker.dataSelezionata.day = giorno + "";
      this.datePicker.dataSelezionata.month = mese + "";
      this.dataSceltaFormatoIta = giorno + "-" + mese + "-" + anno;
      this.dataScelta = anno + "-" + mese + "-" + giorno;
    }
    /* this.ng4LoadingSpinnerService.show(); */
    this.getPrenotazioniRistorante(this.dataScelta);

    //  console.log(this.dataScelta);

  }

  getIsAdmin() {
    if (this.isUserLogged == true) {

      this.tokenValue = this.storage.get('token');

      var headers = new Headers();
      headers.append("Accept", 'application/json');
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', 'Bearer ' + this.tokenValue);


      const requestOptions = new RequestOptions({ headers: headers });

      this.http.get(this.serviziRest.isAdmin, requestOptions).subscribe(data => {

        this.isAdminLogged = data.json();
      }, error => {
        if (error['status'] === 401) {
          alert("E' necessario effettuare il login");
          this.logout();
        }
      });
    }
  }


  getRuoloUtente() {
    if (this.isUserLogged == true) {
      this.tokenValue = this.storage.get('token');

      var headers = new Headers();
      headers.append("Accept", 'application/json');
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', 'Bearer ' + this.tokenValue);


      const requestOptions = new RequestOptions({ headers: headers });

      this.http.get(this.serviziRest.getRuoloUtente, requestOptions).subscribe(data => {
        this.isGestoreBarLogged = data.json() == 5 ? true : false;
        this.isRivenditoreServizi = data.json() == 6 ? true : false;
        this.isBagninoLogged = data.json() == 4 ? true : false;
        this.isSuperAdmin = data.json() == 100 ? true : false;


        //  console.log(this.isGestoreBarLogged);
      }), error => {
        // console.log("oppure qui dentro dà errore");
      };
    }
  }



  getDatiUtente(userData) {



    // console.log(this.tokenValue);
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.tokenValue);
    const requestOptions = new RequestOptions({ headers: headers });

    let postData = {
      "email": userData.email

    }

    this.http.post(this.serviziRest.getUtente, postData, requestOptions)
      .subscribe(data => {
        let tempUserdata = data.json();
        console.log(data.json());
        this.datiUtente.nome = tempUserdata.nome;
        this.datiUtente.cognome = tempUserdata.cognome;
        this.datiUtente.telefono = tempUserdata.telefono;
        this.datiUtente.email = tempUserdata.email;
        this.datiUtente.fotoProfilo = tempUserdata.image;
        if (tempUserdata.image != null && tempUserdata.image != undefined && tempUserdata.image != '') {
          this.fotoProfilo = tempUserdata.image;
        } else {
          this.fotoProfilo = '';
        }

        // console.log(this.globalData.datiUtente.fotoProfilo);

      }, error => {
        // console.log(error.status);

        // console.log(error);
      });


  }

  getDisponibilitaOmbrelloniData(dataInizio, dataFine, idFasciaOraria, provenienzaPage) {  // fa la chiamata per ottenere la disponibilità ombrelloni in una certa data 

    // console.log('verifico disp da:' + provenienzaPage);

    if (dataInizio == '' || dataInizio == null || dataInizio == undefined) {
      dataInizio = this.dataInizio;
    }

    if (this.dataFine == '' || this.dataFine == null || this.dataFine == undefined) {
      this.dataFine = dataInizio;
    }

    if (dataFine == '' || dataFine == null || dataFine == undefined) {
      dataFine = this.dataFine;
    }




    let dataDaModificareInizio = null;
    dataDaModificareInizio = dataInizio.split("-");
    let mese = '' + dataDaModificareInizio[1];
    let giorno = '' + dataDaModificareInizio[2];
    let anno = '' + dataDaModificareInizio[0];

    if (mese.length < 2) mese = '0' + mese;
    if (giorno.length < 2) giorno = '0' + giorno;
    let dataModificataInizio = anno + "-" + mese + "-" + giorno;

    let dataDaModificareFine = null;
    dataDaModificareFine = dataFine.split("-");
    mese = '' + dataDaModificareFine[1];
    giorno = '' + dataDaModificareFine[2];
    anno = '' + dataDaModificareFine[0];

    if (mese.length < 2) mese = '0' + mese;
    if (giorno.length < 2) giorno = '0' + giorno;
    let dataModificataFine = anno + "-" + mese + "-" + giorno;

    if (idFasciaOraria == '' || idFasciaOraria == undefined) {
      idFasciaOraria = 1;
      this.fasciaOrariaSelezionata = 1;
      this.onFasciaOrariaChange();
    }

    /* this.ng4LoadingSpinnerService.show(); */

    this.tokenValue = this.storage.get('token');
    var headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.tokenValue);
    const requestOptions = new RequestOptions({ headers: headers });

    this.checkShowButtoneDeleteAll(this.creaData(), dataModificataInizio);

    //console.log(idFasciaOraria);
    this.localDataOmbr = null;
    this.localDataOmbr = this.http.get(this.serviziRest.getOmbrelloni + dataModificataInizio + "&dataFine=" + dataModificataFine + '&idFasciaOraria=' + idFasciaOraria, requestOptions).subscribe(data => {
      this.dispOmbrelloni = null;
      this.totaleOmbrLiberi = 0;
      this.dispOmbrelloni = data.json();
      /*       console.log(this.dispOmbrelloni); */
      for (let i = 0; i < this.dispOmbrelloni.length; i++) {
        if (this.dispOmbrelloni[i].disponibile == 1) {
          this.totaleOmbrLiberi++;
        }
      }

      setTimeout(function () {
        //this.getSdraioDisponibili(dataRicevuta);
        /*          */
      }.bind(this), 1000);

    }, error => {
      /*        */
      // console.log(error.status);


      //  console.log(error);
    });

  }

  getSdraioDisponibili(dataRicevuta) {  // fa la chiamata per ottenere la disponibilità ombrelloni in una certa data 
    // console.log("ciao");
    let dataDaModificare = null;
    dataDaModificare = dataRicevuta.split("-");
    let mese = '' + dataDaModificare[1];
    let giorno = '' + dataDaModificare[2];
    let anno = '' + dataDaModificare[0];

    if (mese.length < 2) mese = '0' + mese;
    if (giorno.length < 2) giorno = '0' + giorno;
    let dataModificata = anno + "-" + mese + "-" + giorno;


    /* this.ng4LoadingSpinnerService.show(); */
    if (dataRicevuta == undefined || dataRicevuta == null || dataRicevuta == '') {
      dataRicevuta = this.creaData();
    } else {

      this.tokenValue = this.storage.get('token');
      // console.log("Siamo dentro home, getDispOmbrelloni: " + val);
      var headers = new Headers();
      headers.append('Authorization', 'Bearer ' + this.tokenValue);
      const requestOptions = new RequestOptions({ headers: headers });

      //console.log(this.serviziRest.getPrenotazioniRistorante);

      this.http.get(this.serviziRest.getNumeroSdraioDisponibili + "&data=" + dataModificata, requestOptions).subscribe(data => {
        this.totaleSdraioLibere = data.json();
        // console.log(this.totaleSdraioLibere);
      }, error => {
      });

    }
  }







  getPrenotazioniRistorante(dataRicevuta) {  // fa la chiamata per ottenere la disponibilità ristorante in una certa data 
    let dataDaModificare = null;
    dataDaModificare = dataRicevuta.split("-");
    let mese = '' + dataDaModificare[1];
    let giorno = '' + dataDaModificare[2];
    let anno = '' + dataDaModificare[0];

    if (mese.length < 2) mese = '0' + mese;
    if (giorno.length < 2) giorno = '0' + giorno;
    let dataModificata = anno + "-" + mese + "-" + giorno;


    /* this.ng4LoadingSpinnerService.show(); */
    if (dataRicevuta == undefined || dataRicevuta == null || dataRicevuta == '') {
      dataRicevuta = this.creaData();
    } else {

      this.tokenValue = this.storage.get('token');
      // console.log("Siamo dentro getPrenotazioniRistorante con data: " + dataRicevuta);
      var headers = new Headers();
      headers.append('Authorization', 'Bearer ' + this.tokenValue);
      const requestOptions = new RequestOptions({ headers: headers });

      //console.log(this.serviziRest.getPrenotazioniRistorante);
      this.localDataRist = null;
      this.localDataRist = this.http.get(this.serviziRest.getPrenotazioniRistorante + dataModificata + "&idTurno=1", requestOptions).subscribe(data => {
        this.prenotazioniRistPranzo = null;
        this.totaleCopertiPranzo = 0;
        this.prenotazioniRistPranzo = data.json();
        for (let i = 0; i < this.prenotazioniRistPranzo.length; i++) {
          this.totaleCopertiPranzo += this.prenotazioniRistPranzo[i].numeroCoperti;
        }

        if (this.prenotazioniRistPranzo != null && this.prenotazioniRistPranzo.length != 0) {

          if (this.dataUltimoOrdineRist == 0) {
            this.dataUltimoOrdineRist = this.prenotazioniRistPranzo[this.prenotazioniRistPranzo.length - 1].dataPrenotazione;
            this.showAlertNewBookingsRist = true;
            //   console.log(this.dataUltimoOrdineRist);
          }

          if (this.dataUltimoOrdineRist < this.prenotazioniRistPranzo[this.prenotazioniRistPranzo.length - 1].dataPrenotazione) {
            this.dataUltimoOrdineRist = this.prenotazioniRistPranzo[this.prenotazioniRistPranzo.length - 1].dataPrenotazione;
            this.showAlertNewBookingsRist = true;
            window.scrollTo(0, 0);
            this.playAlarm();
          }


          //   console.log(this.prenotazioniRistPranzo);
        }

        setTimeout(function () {
          //   
        }.bind(this), 1000);

      }, error => {
        //
        //    console.log(error.status);


        //   console.log(error);
      });

      this.http.get(this.serviziRest.getPrenotazioniRistorante + dataRicevuta + "&idTurno=2", requestOptions).subscribe(data => {
        this.prenotazioniRistCena = null;
        this.prenotazioniRistCena = data.json();
        this.totaleCopertiCena = 0;
        for (let i = 0; i < this.prenotazioniRistCena.length; i++) {
          this.totaleCopertiCena += this.prenotazioniRistCena[i].numeroCoperti;
        }
        if (this.prenotazioniRistCena != null && this.prenotazioniRistCena.length != 0) {

          if (this.dataUltimoOrdineRist == 0) {
            this.dataUltimoOrdineRist = this.prenotazioniRistCena[this.prenotazioniRistCena.length - 1].dataPrenotazione;
            this.showAlertNewBookingsRist = true;
            //   console.log(this.dataUltimoOrdineRist);
          }

          if (this.dataUltimoOrdineRist < this.prenotazioniRistCena[this.prenotazioniRistCena.length - 1].dataPrenotazione) {
            this.dataUltimoOrdineRist = this.prenotazioniRistCena[this.prenotazioniRistCena.length - 1].dataPrenotazione;
            this.showAlertNewBookingsRist = true;
            window.scrollTo(0, 0);
            this.playAlarm();
          }


          //    console.log(this.prenotazioniRistCena);
        }


        //    console.log(this.prenotazioniRistCena);
        setTimeout(function () {
          /*            */
        }.bind(this), 1000);

      }, error => {
        /*          */
        //   console.log(error.status);


        // console.log(error);
      });

    }


    this.getDisponibilitaPranzo(dataModificata);
    this.getDisponibilitaCena(dataModificata);




  }



  getDisponibilitaPranzo(data) {
    let dataModificata = null;

    if (data == '' || data == null || data == undefined) {
      var d = new Date();
      var mese = '' + this.dataScelta.month;
      var giorno = '' + this.dataScelta.day;
      var anno = this.dataScelta.year
      if (mese.length < 2) mese = '0' + mese;
      if (giorno.length < 2) giorno = '0' + giorno;
      dataModificata = anno + "-" + mese + "-" + giorno;
    } else {

      let dataDaModificare = null;
      dataDaModificare = data.split("-");
      let mese = '' + dataDaModificare[1];
      let giorno = '' + dataDaModificare[2];
      let anno = '' + dataDaModificare[0];

      if (mese.length < 2) mese = '0' + mese;
      if (giorno.length < 2) giorno = '0' + giorno;
      dataModificata = anno + "-" + mese + "-" + giorno;
    }


    var headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.tokenValue);
    headers.append('Content-Type', 'application/json');
    const requestOptions = new RequestOptions({ headers: headers });

    let postData = {
      "data": dataModificata,
      "idTurno": '1',
      "postiDisponibili": '0'
    }



    this.http.post(this.serviziRest.getDisponibilitaMassima, postData, requestOptions)
      .subscribe(data => {

        this.postiDisponibiliPranzo = data.json();
      }, error => {

        //   console.log(error.status);
        //   console.log(error);
      });


  }

  getDisponibilitaCena(data) {
    let dataModificata = null;

    if (data == '' || data == null || data == undefined) {
      var d = new Date();
      var mese = '' + this.dataScelta.month;
      var giorno = '' + this.dataScelta.day;
      var anno = this.dataScelta.year
      if (mese.length < 2) mese = '0' + mese;
      if (giorno.length < 2) giorno = '0' + giorno;
      dataModificata = anno + "-" + mese + "-" + giorno;
    } else {

      let dataDaModificare = null;
      dataDaModificare = data.split("-");
      let mese = '' + dataDaModificare[1];
      let giorno = '' + dataDaModificare[2];
      let anno = '' + dataDaModificare[0];

      if (mese.length < 2) mese = '0' + mese;
      if (giorno.length < 2) giorno = '0' + giorno;
      dataModificata = anno + "-" + mese + "-" + giorno;
    }


    var headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.tokenValue);
    headers.append('Content-Type', 'application/json');
    const requestOptions = new RequestOptions({ headers: headers });

    let postData = {
      "data": dataModificata,
      "idTurno": '2',
      "postiDisponibili": '0'
    }

    this.http.post(this.serviziRest.getDisponibilitaMassima, postData, requestOptions)
      .subscribe(data => {
        this.postiDisponibiliCena = data.json();

      }, error => {

        //    console.log(error.status);
        //    console.log(error);
      });

  }


  getListaUtentiByRuolo(idRuolo) {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.tokenValue);
    const requestOptions = new RequestOptions({ headers: headers });
    this.http.get(this.serviziRest.getUtentiByIdRuolo + idRuolo, requestOptions).subscribe(data => {

      if (idRuolo == '6') {
        this.listaUtentiRivenditori = data.json();
      }
      setTimeout(function () {
        /*          */
        /*         console.log(this.listaUtenti); */
      }.bind(this), 1000);

    })
  }

  getListaUtenti() { // serve ad ottenere la lista degli utenti registrati
    /* this.ng4LoadingSpinnerService.show(); */

    var headers = new Headers();

    headers.append('Authorization', 'Bearer ' + this.tokenValue);


    const requestOptions = new RequestOptions({ headers: headers });



    this.listaUtentiData = this.http.get(this.serviziRest.getListaUtenti, requestOptions).subscribe(data => {

      this.listaUtenti = data.json();
      this.totaleUtentiRegistrati = this.listaUtenti.length;
      setTimeout(function () {

        /*         console.log(this.listaUtenti); */
      }.bind(this), 1000);

    })
  }
  creaDataUTC() {
    var d = new Date();
    var g = d.getUTCDate();
    var m = d.getUTCMonth() + 1;
    var data = d.getUTCFullYear() + "-";
    if (m < 10) {
      data = data + "0";
    }
    data = data + m + "-";
    if (g < 10) {
      data = data + "0";
    }
    data = data + g + "";
    return data;
  }

  getOrdiniBar() {
    /* this.ng4LoadingSpinnerService.show(); */
    var dataScelta = this.creaData();
    this.tokenValue = this.storage.get('token');

    //console.log("Siamo dentro gestione magazzino con token: " + this.tokenValue);

    var headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.tokenValue);
    const requestOptions = new RequestOptions({ headers: headers });

    this.http.get(this.serviziRest.getOrdiniBarGiorno + dataScelta, requestOptions).subscribe(data => {
      let itemsOrdiniBarTemp = null;
      itemsOrdiniBarTemp = data.json();
      this.itemsOrdiniBar = itemsOrdiniBarTemp;
      //   console.log(this.itemsOrdiniBar);

      if (this.itemsOrdiniBar != null && this.itemsOrdiniBar.length != 0) {

        if (this.dataUltimoOrdineBar == 0) {
          this.dataUltimoOrdineBar = this.itemsOrdiniBar[this.itemsOrdiniBar.length - 1].dataOrdine;
          this.showAlertNewBookingsBar = true;
          //console.log(this.dataUltimoOrdineBar);
        }

        if (this.dataUltimoOrdineBar < this.itemsOrdiniBar[this.itemsOrdiniBar.length - 1].dataOrdine) {
          this.dataUltimoOrdineBar = this.itemsOrdiniBar[this.itemsOrdiniBar.length - 1].dataOrdine;
          this.showAlertNewBookingsBar = true;
          window.scrollTo(0, 0);
          this.playAlarm();
        }
      }
      for (let i = 0; i < this.itemsOrdiniBar.length; i++) {
        var utcSeconds = this.itemsOrdiniBar[i].dataOrdine;
        utcSeconds += 0; // attualmente la timezone corrisponde a Roma. Tutto ok.
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCMilliseconds(utcSeconds);
        this.itemsOrdiniBar[i].prezzoTotaleOrdini = 0;

        let ora = "" + d.getHours();
        let minuti = "" + d.getMinutes();


        let monthD = "" + (d.getMonth() + 1);
        let dayD = "" + d.getDate();

        if (ora.length < 2) {
          ora = '0' + ora;
        }
        if (minuti.length < 2) {
          minuti = '0' + minuti;
        }

        if (monthD.length < 2) {
          monthD = '0' + monthD;
        }
        if (dayD.length < 2) {
          dayD = '0' + dayD;
        }

        this.itemsOrdiniBar[i].dataOrdineStringa = dayD + "/" + monthD + "/" + d.getFullYear() + " " + ora + ":" + minuti;
        for (let j = 0; j < this.itemsOrdiniBar[i].elementiOrdineBar.length; j++) {
          this.itemsOrdiniBar[i].elementiOrdineBar[j].prezzoTotale = this.itemsOrdiniBar[i].elementiOrdineBar[j].prezzo * this.itemsOrdiniBar[i].elementiOrdineBar[j].quantita
          this.itemsOrdiniBar[i].prezzoTotaleOrdini += (this.itemsOrdiniBar[i].elementiOrdineBar[j].prezzoTotale) * 1;
        }
      }
      setTimeout(function () {

        //   console.log(this.listaUtenti);
      }.bind(this), 2000);



      //    console.log(this.itemsOrdiniBar);

    }, error => {

      //   console.log(error.status);


      //   console.log(error);
    });

  }



  creaData() {
    var d = new Date();
    var mese = '' + (d.getMonth() + 1);
    var giorno = '' + d.getDate();
    var anno = d.getFullYear();
    if (mese.length < 2) mese = '0' + mese;
    if (giorno.length < 2) giorno = '0' + giorno;
    return anno + "-" + mese + "-" + giorno;
  }

  creaDataDatePicker() {
    var d = new Date();
    var mese = '' + (d.getMonth() + 1);
    var giorno = '' + d.getDate();
    var anno = d.getFullYear();
    if (mese.length < 2) mese = '0' + mese;
    if (giorno.length < 2) giorno = '0' + giorno;

    this.datePicker.dataSelezionata.year = anno + "";
    this.datePicker.dataSelezionata.day = giorno + "";
    this.datePicker.dataSelezionata.month = mese + "";
    this.dataScelta = anno + "-" + mese + "-" + giorno;
  }

  playAlarm() {
    let audio = new Audio();
    audio.src = "./assets/newBooking.mp3";
    audio.load();
    audio.play();
  }
  logout() {
    this.isUserLogged = false;
    this.loggedUser = null;
    this.tokenValue = '';
    this.storage.set('token', '');
    this.storage.set('username', '');
    this.router.navigateByUrl("/", { skipLocationChange: false });

  }



  aggiornaDatiRiepilogoIncassi(datePicker) {
    if (datePicker.year != '' && datePicker.year != undefined && datePicker.year != '--') {
      this.dataScelta = datePicker.year + "-" + datePicker.month + "-" + datePicker.day;
      var gg = (datePicker.day * 1);
      var mm = (datePicker.month * 1);
      this.dataSceltaFormatoIta = ((gg < 10) ? ("0" + gg) : datePicker.day) + "-" + ((mm < 10) ? ("0" + mm) : datePicker.month) + "-" + datePicker.year;

    } else {
      var d = new Date();
      var mese = '' + (d.getMonth() + 1);
      var giorno = '' + d.getDate();
      var anno = d.getFullYear();
      if (mese.length < 2) mese = '0' + mese;
      if (giorno.length < 2) giorno = '0' + giorno;

      this.datePicker.dataSelezionata.year = anno + "";
      this.datePicker.dataSelezionata.day = giorno + "";
      this.datePicker.dataSelezionata.month = mese + "";
      this.dataSceltaFormatoIta = giorno + "-" + mese + "-" + anno;
      this.dataScelta = anno + "-" + mese + "-" + giorno;
    }

    this.getRiepilogoIncassi();

  }


  getRiepilogoIncassi() {  // fa la chiamata per ottenere la disponibilità ristorante in una certa data 
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.tokenValue);

    const requestOptions = new RequestOptions({ headers: headers });

    this.http.get(this.serviziRest.getRiepilogoIncassi, requestOptions).subscribe(data => {
      this.riepilogoIncassi = data.json();
      this.filtraIncassi();
    });
  }


  filtraIncassi() {

    this.resettaIncassi();

    for (let i = 0; i < this.riepilogoIncassi.length; i++) {
      if (this.riepilogoIncassi[i].mese != null) {
        this.riepilogoIncassi[i].mese = this.riepilogoIncassi[i].mese.length < 2 ? 0 + this.riepilogoIncassi[i].mese : this.riepilogoIncassi[i].mese;
      }
      if (this.riepilogoIncassi[i].giorno != null) {
        let dateObject = new Date(this.riepilogoIncassi[i].giorno);
        this.riepilogoIncassi[i].giorno = dateObject.toLocaleString("it-IT", { day: "numeric" }).length < 2 ? 0 + dateObject.toLocaleString("it-IT", { day: "numeric" }) : dateObject.toLocaleString("it-IT", { day: "numeric" });
      }

      // calcola incassi dell'anno

      if (this.riepilogoIncassi[i].mese == null && this.riepilogoIncassi[i].giorno == null) {
        this.riepilogoIncassoAnnoCorrente.totale += parseInt(this.riepilogoIncassi[i].stripe, 10) + parseInt(this.riepilogoIncassi[i].paypal, 10) + parseInt(this.riepilogoIncassi[i].braintree, 10) + parseInt(this.riepilogoIncassi[i].allaCassa, 10);

        this.riepilogoIncassoAnnoCorrente.totaleStripeOmbrelloni += parseInt(this.riepilogoIncassi[i].stripe, 10);
        this.riepilogoIncassoAnnoCorrente.totalePayPalOmbrelloni += parseInt(this.riepilogoIncassi[i].paypal, 10);
        this.riepilogoIncassoAnnoCorrente.totaleBraintreeOmbrelloni += parseInt(this.riepilogoIncassi[i].braintree, 10);
        this.riepilogoIncassoAnnoCorrente.totaleContantiOmbrelloni += parseInt(this.riepilogoIncassi[i].allaCassa, 10);

      }
      // calcola incassi del mese
      if (this.riepilogoIncassi[i].mese != null && this.riepilogoIncassi[i].giorno == null && this.riepilogoIncassi[i].mese == this.datePicker.dataSelezionata.month) {
        this.riepilogoIncassoMeseCorrente.totale += parseInt(this.riepilogoIncassi[i].stripe, 10) + parseInt(this.riepilogoIncassi[i].paypal, 10) + parseInt(this.riepilogoIncassi[i].braintree, 10) + parseInt(this.riepilogoIncassi[i].allaCassa, 10);

        this.riepilogoIncassoMeseCorrente.totaleStripeOmbrelloni += parseInt(this.riepilogoIncassi[i].stripe, 10);
        this.riepilogoIncassoMeseCorrente.totalePayPalOmbrelloni += parseInt(this.riepilogoIncassi[i].paypal, 10);
        this.riepilogoIncassoMeseCorrente.totaleBraintreeOmbrelloni += parseInt(this.riepilogoIncassi[i].braintree, 10);
        this.riepilogoIncassoMeseCorrente.totaleContantiOmbrelloni += parseInt(this.riepilogoIncassi[i].allaCassa, 10);

      }

      // calcola incassi del giorno
      if (this.riepilogoIncassi[i].mese == this.datePicker.dataSelezionata.month && this.riepilogoIncassi[i].giorno == this.datePicker.dataSelezionata.day) {
        this.riepilogoIncassoGiornoCorrente.totale = parseInt(this.riepilogoIncassi[i].stripe, 10) + parseInt(this.riepilogoIncassi[i].paypal, 10) + parseInt(this.riepilogoIncassi[i].braintree, 10) + parseInt(this.riepilogoIncassi[i].allaCassa, 10);

        if (this.riepilogoIncassi[i].fonte == 'ombrelloni') {
          this.riepilogoIncassoGiornoCorrente.totaleStripeOmbrelloni = parseInt(this.riepilogoIncassi[i].stripe, 10);
          this.riepilogoIncassoGiornoCorrente.totalePayPalOmbrelloni = parseInt(this.riepilogoIncassi[i].paypal, 10);
          this.riepilogoIncassoGiornoCorrente.totaleBraintreeOmbrelloni = parseInt(this.riepilogoIncassi[i].braintree, 10);
          this.riepilogoIncassoGiornoCorrente.totaleContantiOmbrelloni = parseInt(this.riepilogoIncassi[i].allaCassa, 10);
          this.riepilogoIncassoGiornoCorrente.totaleOmbrelloni += this.riepilogoIncassoGiornoCorrente.totaleContantiOmbrelloni + this.riepilogoIncassoGiornoCorrente.totaleBraintreeOmbrelloni
            + this.riepilogoIncassoGiornoCorrente.totalePayPalOmbrelloni + this.riepilogoIncassoGiornoCorrente.totaleStripeOmbrelloni;
        } else if (this.riepilogoIncassi[i].fonte == 'bar') {
          this.riepilogoIncassoGiornoCorrente.totaleStripeBar = parseInt(this.riepilogoIncassi[i].stripe, 10);
          this.riepilogoIncassoGiornoCorrente.totalePayPalBar = parseInt(this.riepilogoIncassi[i].paypal, 10);
          this.riepilogoIncassoGiornoCorrente.totaleBraintreeBar = parseInt(this.riepilogoIncassi[i].braintree, 10);
          this.riepilogoIncassoGiornoCorrente.totaleContantiBar = parseInt(this.riepilogoIncassi[i].allaCassa, 10);
          this.riepilogoIncassoGiornoCorrente.totaleBar += this.riepilogoIncassoGiornoCorrente.totaleContantiBar + this.riepilogoIncassoGiornoCorrente.totaleBraintreeBar
            + this.riepilogoIncassoGiornoCorrente.totalePayPalBar + this.riepilogoIncassoGiornoCorrente.totaleStripeBar;
        }
      }
    }
    /*     console.log(this.riepilogoIncassi); */
  }

  resettaIncassi() {
    this.riepilogoIncassoMeseCorrente.totale = 0;
    this.riepilogoIncassoAnnoCorrente.totale = 0;
    this.riepilogoIncassoGiornoCorrente.totale = 0;
    this.riepilogoIncassoGiornoCorrente.totaleBar = 0;
    this.riepilogoIncassoGiornoCorrente.totaleOmbrelloni = 0;

    this.riepilogoIncassoMeseCorrente.totaleStripeOmbrelloni = 0;
    this.riepilogoIncassoAnnoCorrente.totaleStripeOmbrelloni = 0;
    this.riepilogoIncassoGiornoCorrente.totaleStripeOmbrelloni = 0;

    this.riepilogoIncassoMeseCorrente.totalePayPalOmbrelloni = 0;
    this.riepilogoIncassoAnnoCorrente.totalePayPalOmbrelloni = 0;
    this.riepilogoIncassoGiornoCorrente.totalePayPalOmbrelloni = 0;

    this.riepilogoIncassoMeseCorrente.totaleContantiOmbrelloni = 0;
    this.riepilogoIncassoAnnoCorrente.totaleContantiOmbrelloni = 0;
    this.riepilogoIncassoGiornoCorrente.totaleContantiOmbrelloni = 0;

    this.riepilogoIncassoMeseCorrente.totaleBraintreeOmbrelloni = 0;
    this.riepilogoIncassoAnnoCorrente.totaleBraintreeOmbrelloni = 0;
    this.riepilogoIncassoGiornoCorrente.totaleBraintreeOmbrelloni = 0;

    this.riepilogoIncassoMeseCorrente.totaleStripeBar = 0;
    this.riepilogoIncassoAnnoCorrente.totaleStripeBar = 0;
    this.riepilogoIncassoGiornoCorrente.totaleStripeBar = 0;

    this.riepilogoIncassoMeseCorrente.totalePayPalBar = 0;
    this.riepilogoIncassoAnnoCorrente.totalePayPalBar = 0;
    this.riepilogoIncassoGiornoCorrente.totalePayPalBar = 0;

    this.riepilogoIncassoMeseCorrente.totaleContantiBar = 0;
    this.riepilogoIncassoAnnoCorrente.totaleContantiBar = 0;
    this.riepilogoIncassoGiornoCorrente.totaleContantiBar = 0;

    this.riepilogoIncassoMeseCorrente.totaleBraintreeBar = 0;
    this.riepilogoIncassoAnnoCorrente.totaleBraintreeBar = 0;
    this.riepilogoIncassoGiornoCorrente.totaleBraintreeBar = 0;


  }
}
