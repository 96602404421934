import {Component} from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { GlobalDataService } from '../../providers/global-data.service';

@Component({
  selector: 'datepicker-popup',
  templateUrl: './datepicker-popup.component.html',
  styleUrls: ['./datepicker-popup.component.css'],

})
export class DatePickerComponent {
  model;
  datePicker = { dataSelezionata: {'year' : '', 'month': '', 'day' : ''} };
  constructor(public globalData: GlobalDataService) {
  }
  
}

