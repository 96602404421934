import { Component } from '@angular/core';
import { interval } from 'rxjs';
import { GlobalDataService } from './providers/global-data.service';
import { NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { NgbDateFRParserFormatter } from "./ngb-date-fr-parser-formatter"


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [{ provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }]
})
export class AppComponent {
  title = 'gestionale-singlefin';

  constructor(public globalData: GlobalDataService) {



    if (!this.globalData.modificaInCorso && this.globalData.isUserLogged) {
      /*       this.globalData.ng4LoadingSpinnerService.show(); */
      if (this.globalData.dataScelta == null || this.globalData.dataScelta == '' || this.globalData.dataScelta == undefined) {
        var d = new Date();
        var mese = '' + (d.getMonth() + 1);
        var giorno = '' + d.getDate();
        var anno = d.getFullYear();
        if (mese.length < 2) mese = '0' + mese;
        if (giorno.length < 2) giorno = '0' + giorno;
        this.globalData.dataScelta = anno + "-" + mese + "-" + giorno;
      }

      //console.log("Sto aggiornado il contenuto in data " + this.globalData.dataScelta + " della gestione ombrelloni, del riepilogo, delle prenotazioni ristorante e della lista utenti " + new Date());
    } else if (this.globalData.isUserLogged) {
      //   console.log("Modifiche in corso, non effettuo il refresh dei dati " + new Date());
    }

  }
}
