import { Pipe, PipeTransform } from "@angular/core";
import { GlobalDataService } from "../providers/global-data.service";
import { distinct } from 'rxjs/operators';
import { from } from 'rxjs';
@Pipe({
    name: 'stringFilter'
})

export class StringFilterPipe implements PipeTransform {

    tempData: any[] = [];
 
    itemsFiltrati: any; // variabile che conterra' l'oggetto filtrato nella barra di ricerca in Anagrafica Clienti

    constructor(public globalData: GlobalDataService) { }
    transform(items: any[], filter: string) {
        if (!filter || filter === '') {
            return items;
        }

        for (let i = 0; i < items.length; i++) {
            if ((items[i].nome.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                || (items[i].cognome.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                || (items[i].email.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                || (items[i].telefono.indexOf(filter) !== -1)) {
                this.tempData.push(items[i]);
            }

        }

        this.itemsFiltrati = [];
        from(this.tempData).pipe(
            distinct()
        ).subscribe(element => {
            this.itemsFiltrati.push(element);
        });

        this.tempData = [];
        return this.itemsFiltrati;
    }
}