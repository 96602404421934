export class ItemDisponibModel {

    fasciaOraria: string;
    data: string;
    numeroCoperti: string;
 
    constructor() { }

    reset() {

        this.fasciaOraria = "";
        this.data = "";
        this.numeroCoperti = "";
    }
}