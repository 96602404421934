import { Pipe, PipeTransform } from "@angular/core";
import { GlobalDataService } from "../providers/global-data.service";
import { distinct } from 'rxjs/operators';
import { from } from 'rxjs';
@Pipe({
    name: 'stringFilterOrdiniBar'
})

export class StringFilterPipeOrdiniBar implements PipeTransform {

    tempData: any[] = [];

    itemsFiltrati = [];

    constructor(public globalData: GlobalDataService) { }
    transform(items: any[], filter: string) {
        if (!filter || filter === '') {
            return items;
        }
        //console.log(items);
        for (let i = 0; i < items.length; i++) {


            if (this.globalData.filtraOrdiniAnnulati == true) {
                for (let j = 0; j < items[i].ombrelloni.length; j++) {

                    if (items[i].ombrelloni[j].annullato == 1) {
                        this.tempData.push(items[i]);
                    }

                }
            } else if (this.globalData.filtraOrdiniAnnulati == false) {
                if ((items[i].utente.nome.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                    || (items[i].utente.cognome.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                    || (items[i].utente.email.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                    || (items[i].idOrdineBar.toString().indexOf(filter) !== -1)) {
                    this.tempData.push(items[i]);
                }
            }

        }


        this.itemsFiltrati = [];
        from(this.tempData).pipe(
            distinct()
        ).subscribe(element => {
            this.itemsFiltrati.push(element);
        });

        this.tempData = [];
        return this.itemsFiltrati;
    }
}