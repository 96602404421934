import { Component, Injectable, OnInit } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { GlobalDataService } from '../../providers/global-data.service';
import { Validators, FormControl, FormGroup } from '@angular/forms';

import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { GlobalFuncService } from '../../providers/global-func.service';
import { TranslationWidth } from '@angular/common';


const I18N_VALUES = {
  'it': {
    weekdays: ['Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa', 'Do'],
    months: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'it';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    const fullWeekdays = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica'];
    const abbreviatedWeekdays = ['D', 'L', 'M', 'M', 'G', 'V', 'S', 'D'];

    switch (width) {
      case TranslationWidth.Short:
      case TranslationWidth.Narrow:
        return abbreviatedWeekdays[weekday];
      default:
        return fullWeekdays[weekday];
    }
  }

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Component({
  selector: 'ngbd-datepicker-range',
  templateUrl: './datepicker-range.html',
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
  styles: [`
    .custom-day {
      text-align: center;
      padding: 0.185rem 0.25rem;
      display: inline-block;
      height: 2rem;
      width: 2rem;
    }

    .ngb-dp-day.hidden {
      cursor: default;
      display: block !important;
  }
  .ngb-dp-day .hidden {
      display: block !important;
      visibility: hidden !important;
  }

    .custom-day.focused {
      background-color: #e6e6e6;
    }
    .custom-day.range, .custom-day:hover {
      background-color: rgb(2, 117, 216);
      color: white;
    }
    .custom-day.faded {
      background-color: rgba(2, 117, 216, 0.5);
    }
  `]
})
export class NgbdDatepickerRange {

  hoveredDate: NgbDate;

  fromDate: NgbDate;
  toDate: NgbDate;

  constructor(calendar: NgbCalendar, public globalData: GlobalDataService) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getToday();
    //this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  scegliPeriodo(da, a) {
    this.globalData.showPaginaAbbonamenti = true;
    let paragoneInizio = "";
    let paragoneFine = "";

    if (da != null) {
      if (da.year != '' && da.year != undefined && da.year != '--') {
        var gg = (da.day * 1);
        var mm = (da.month * 1);
        this.globalData.dataInizio = da.year + "-" + ((mm < 10) ? ("0" + mm) : da.month) + "-" + ((gg < 10) ? ("0" + gg) : da.day);
        this.globalData.dataInizioToShow = ((gg < 10) ? ("0" + gg) : da.day) + "-" + ((mm < 10) ? ("0" + mm) : da.month) + "-" + da.year;
        paragoneInizio = "" + da.year + ((mm < 10) ? ("0" + mm) : da.month) + ((gg < 10) ? ("0" + gg) : da.day);
      }
    }

    if (a != null) {

      if (a.year != '' && a.year != undefined && a.year != '--') {
        var gg = (a.day * 1);
        var mm = (a.month * 1);
        this.globalData.dataFine = a.year + "-" + ((mm < 10) ? ("0" + mm) : a.month) + "-" + ((gg < 10) ? ("0" + gg) : a.day);
        this.globalData.dataFineToShow = ((gg < 10) ? ("0" + gg) : a.day) + "-" + ((mm < 10) ? ("0" + mm) : a.month) + "-" + a.year;
        paragoneFine = "" + a.year + ((mm < 10) ? ("0" + mm) : a.month) + ((gg < 10) ? ("0" + gg) : a.day);
      }
    }

    if (paragoneFine >= paragoneInizio) {

      this.globalData.getDisponibilitaOmbrelloniData(this.globalData.dataInizio, this.globalData.dataFine, 1, 'da date picker');
    } else {
      alert("Correggere le date, la data di fine è minore di quella di inizio");
      this.globalData.dataInizioToShow = "";
      this.globalData.dataFineToShow = "";
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }
}



@Component({
  selector: 'ngbd-datepicker-ita',
  templateUrl: './datepicker-ita.component.html',
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }] // define custom NgbDatepickerI18n provider
})
export class NgbdDatepickerI18n {
  model: NgbDateStruct;
  datePicker = { dataSelezionata: { 'year': '', 'month': '', 'day': '' } };

  constructor(public globalData: GlobalDataService) {
  }
}


@Component({
  selector: 'ngbd-datepicker-ita-ombr-fino-a',
  templateUrl: './datepicker-ita-ombr-fino-a.component.html',
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }] // define custom NgbDatepickerI18n provider
})
export class NgbdDatepickerOmbrFinoA {
  model: NgbDateStruct;
  datePicker = { dataSelezionataFinoA: { 'day': '', 'month': '', 'year': '' } };
  constructor(public globalData: GlobalDataService) {

  }
}



@Component({
  selector: 'ngbd-datepicker-ita-ombr',
  templateUrl: './datepicker-ita-ombr.component.html',
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }] // define custom NgbDatepickerI18n provider
})
export class NgbdDatepickerOmbr {
  model: NgbDateStruct;
  datePicker = { dataSelezionata: { 'year': '', 'month': '', 'day': '' } };
  constructor(public globalData: GlobalDataService) {

  }
}


@Component({
  selector: 'ngbd-datepicker-ita-ombr-ord',
  templateUrl: './datepicker-ita-ombr-ord.component.html',
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }] // define custom NgbDatepickerI18n provider
})
export class NgbdDatepickerOmbrOrd {
  model: NgbDateStruct;
  datePicker = { dataSelezionata: { 'year': '', 'month': '', 'day': '' } };


  constructor(public globalData: GlobalDataService) {


  }



}





@Component({
  selector: 'ngbd-datepicker-ita-rist',
  templateUrl: './datepicker-ita-rist.component.html',
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }] // define custom NgbDatepickerI18n provider
})
export class NgbdDatepickerRist {
  model: NgbDateStruct;
  datePicker = { dataSelezionata: { 'year': '', 'month': '', 'day': '' } };


  constructor(public globalData: GlobalDataService) {


  }

}


@Component({
  selector: 'ngbd-datepicker-ita-rist-add',
  templateUrl: './datepicker-ita-rist-add.component.html',
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }] // define custom NgbDatepickerI18n provider
})
export class NgbdDatepickerRistAdd implements OnInit {
  model: NgbDateStruct;
  datePicker = { dataSelezionata: { 'year': '', 'month': '', 'day': '' } };

  constructor(public globalData: GlobalDataService) {


  }

  ngOnInit() {
    this.globalData.ristoranteAddForm = new FormGroup({
      data: new FormControl('', [Validators.required])
    });
  }

}

@Component({
  selector: 'ngbd-datepicker-ita-rist-edit',
  templateUrl: './datepicker-ita-rist-edit.component.html',
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }] // define custom NgbDatepickerI18n provider
})
export class NgbdDatepickerRistEdit implements OnInit {

  model: NgbDateStruct;
  datePicker = { dataSelezionata: { 'year': '', 'month': '', 'day': '' } };


  constructor(public globalData: GlobalDataService) {


  }

  ngOnInit() {
    this.globalData.ristoranteEditForm = new FormGroup({
      data: new FormControl('', [Validators.required])
    });
  }

}



@Component({
  selector: 'ngbd-datepicker-ita-rist-disp',
  templateUrl: './datepicker-ita-rist-disp.component.html',
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }] // define custom NgbDatepickerI18n provider
})
export class NgbdDatepickerRistDisp implements OnInit {

  model: NgbDateStruct;
  datePicker = { dataSelezionata: { 'year': '', 'month': '', 'day': '' } };


  constructor(public globalData: GlobalDataService) {


  }

  ngOnInit() {
    this.globalData.ristoranteDispForm = new FormGroup({
      data: new FormControl('', [Validators.required])
    });
  }


}

@Component({
  selector: 'ngbd-datepicker-ita-riepilogo-incassi',
  templateUrl: './datepicker-ita-riepilogo-incassi.component.html',
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }] // define custom NgbDatepickerI18n provider
})
export class NgbdDatepickerOmbrRiepilogoIncassi {
  model: NgbDateStruct;
  datePicker = { dataSelezionata: { 'year': '', 'month': '', 'day': '' } };


  constructor(public globalData: GlobalDataService) {


  }



}
